import prependNoneOption from '../../util/prependNoneOption';
import { useAPI } from './useAPI';

interface IUseEntityListByGetRQ {
  url: string;
  params?: Record<string, any>;
  staleTime?: number;
  enabled?: boolean;
  affixNoneOption?: boolean;
  onSuccess?: Function;
}

export function useRetrieveListByGet({
  url,
  params,
  staleTime = 0,
  enabled = true,
  affixNoneOption = false,
  onSuccess,
}: IUseEntityListByGetRQ) {

  const { data, ...rest } = useAPI({
    method: 'get',
    url,
    params,
    data: {},
    staleTime,
    enabled,
    onSuccess,
  });

  let { result } = data || {};

  if (affixNoneOption) result = prependNoneOption(result || []);

  return {
    data: result,
    ...rest,
  };
}
