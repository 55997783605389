import { AxiosError } from 'axios';
import { GenericMutationResult } from 'src/compass/shared/hooks/reactQuery/useCasMutation';
import CasErrorDialog from './CasErrorDialog';

export interface CasMutationErrorDialogProps extends GenericMutationResult {
  title?: string;
  onClose: VoidFunction;
}

export default function CasMutationErrorDialog({
  title,
  mutation,
  onClose,
}: CasMutationErrorDialogProps) {
  return (
    <CasErrorDialog
      open
      title={title ? `ERROR: ${title}` : 'ERROR'}
      error={mutation.error as AxiosError}
      onClose={() => {
        mutation.reset();
        onClose();
      }}
    />
  );
}
