// @mui
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import { MomentInput } from 'moment';
import { formatDate, getDateInMoment, DateFormat } from 'src/compass/shared/util/casMoment';

interface CasDatePickerProps extends Omit<DatePickerProps<MomentInput>, 'onChange'> {
  onChange: (event: { target: { value: string } }, value: string) => void;
}

export default function CasDatePicker({
  label,
  value,
  onChange,
  minDate,
  ...other
}: CasDatePickerProps) {
  const handleChange = (date: moment.MomentInput | null) => {
    const formattedDate = formatDate(date, DateFormat.YMD);
    onChange({ target: { value: formattedDate } }, formattedDate);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
        label={label}
        format="MM/DD/YYYY"
        value={value && getDateInMoment(value)}
        onChange={handleChange}
        minDate={minDate && getDateInMoment(minDate)}
        sx={{ maxWidth: 160 }}
        slotProps={{
          field: {
            readOnly: true,
          },
        }}
        {...other}
      />
    </LocalizationProvider>
  );
}
