import { useState } from 'react';
import { AppBar, Toolbar, Typography } from '@mui/material';
import { paths } from 'src/compass/site/routes';
import useUpdateMutation from 'src/compass/shared/hooks/reactQuery/useUpdateMutation';
import { CasIconButton } from 'src/compass/shared/components';
import { onSuccessEnqueueSnackbar } from 'src/compass/shared/util/onSuccessEnqueueSnackbar';

type ImpersonateUserAppBarProps = {
  impersonatingUserName: string;
};

export default function ImpersonateUserAppBar({
  impersonatingUserName,
}: ImpersonateUserAppBarProps) {
  const mutation = useUpdateMutation({
    url: 'Login/StopImpersonate',
    onSuccess: (data) => {
      onSuccessEnqueueSnackbar(data);
      window.location.href = paths.home;
    },
  });
  const { mutate } = mutation;
  const [isToolbarAtBottom, setIsToolbarAtBottom] = useState(true);

  const handleCancelImpersonating = () => mutate({});

  const toggleToolbarPosition = () => setIsToolbarAtBottom((prev) => !prev);

  return (
    <AppBar
      position="fixed"
      color="primary"
      sx={{
        ...(isToolbarAtBottom && {
          top: 'auto',
          bottom: 0,
        }),
        zIndex: (theme) => theme.zIndex.modal + 1,
      }}
    >
      <Toolbar variant="dense" sx={{ justifyContent: 'space-between' }}>
        <CasIconButton icon="iconamoon:swap-fill" onClick={toggleToolbarPosition} />
        <Typography variant="h4">Impersonating {impersonatingUserName}</Typography>
        <CasIconButton icon="vaadin:close" onClick={handleCancelImpersonating} />
      </Toolbar>
    </AppBar>
  );
}
