import {
  CasRHFTextField,
  CasReactHookFormDialog,
  CasDeleteButton,
} from 'src/compass/shared/components';
import { Typography } from '@mui/material';
import { DeleteSchema } from 'src/compass/shared/schemas/DeleteForm';
import { onSuccessEnqueueSnackbar } from 'src/compass/shared/util/onSuccessEnqueueSnackbar';
import useDeleteMutation from 'src/compass/shared/hooks/reactQuery/useDeleteMutation';

import { ICasBaseDialog } from '../CasDialog/CasBaseDialog';

interface ICasDeletePopUp extends Omit<ICasBaseDialog, 'title'> {
  entity: string;
  entityTitle: string;
  id: string;
  onDeleted: VoidFunction;
}

export default function CasDeletePopUp({
  entity,
  entityTitle,
  id,
  onDeleted,
  onClose,
  ...other
}: ICasDeletePopUp) {
  const mutation = useDeleteMutation({
    url: `${entity}/${id}`,
    onSuccess: (data) => {
      onDeleted();
      onSuccessEnqueueSnackbar(data);
    },
  });

  const { mutate } = mutation;

  const handleEntityDelete = () => mutate({});

  return (
    <CasReactHookFormDialog
      {...{ onClose, ...other }}
      title={`Delete ${entityTitle}`}
      schema={DeleteSchema}
      onSubmit={handleEntityDelete}
      submitButton={CasDeleteButton}
    >
      <Typography>
        This Record will be permanently deleted from the system. This action can not be undone.
      </Typography>
      <CasRHFTextField name="deleteInput" label="To confirm, type DELETE below and Press Enter" />
    </CasReactHookFormDialog>
  );
}
