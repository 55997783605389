import CasBaseDialog, { ICasBaseDialog } from './CasBaseDialog';

type AlertDialogProps = Omit<ICasBaseDialog, 'title'> & {
  title?: string;
};

export default function CasAlertDialog({ title = '', children, ...props }: AlertDialogProps) {
  return (
    <CasBaseDialog {...props} title={title} fullScreen={false}>
      {children}
    </CasBaseDialog>
  );
}
