import { CasSelect, componentProps } from 'src/compass/shared/components';
import prependAllOption from 'src/compass/shared/util/prependAllOption';
import prependNoneOption from 'src/compass/shared/util/prependNoneOption';

export const YESNO_OPTIONS = [
  { name: 'Yes', id: true },
  { name: 'No', id: false },
];

export const YESNOALL_OPTIONS = prependAllOption(YESNO_OPTIONS);
export const YESNONONE_OPTIONS = prependNoneOption(YESNO_OPTIONS);

export function CasYesNoSelect({ includeAllOption, includeNoneOption, ...rest }: componentProps) {
  let options: any[] = YESNO_OPTIONS;
  if (includeAllOption) options = YESNOALL_OPTIONS;
  if (includeNoneOption) options = YESNONONE_OPTIONS;

  return <CasSelect options={options} {...rest} />;
}
