// @mui
import { CircularProgress, Backdrop } from '@mui/material';
import { FocusTrap } from '@mui/base';

export default function CasLoaderWithBackdrop({caption=null}:any) {
  return (
    <FocusTrap open>
      <Backdrop open sx={{ zIndex: (theme) => theme.zIndex.modal + 1 }}>
        <div style={{textAlign: "center"}}>
          <CircularProgress color="primary" /> 
          {caption &&
            <div style={{color:"white", fontWeight:"bold", padding:"0 4px"}}>
              {caption}
            </div>
          }
        </div>
      </Backdrop>
    </FocusTrap>
  );
}
