import { AxiosError } from 'axios';
import { useAPI } from './useAPI';

/*
hook will be used for fetching listing for table UI
Error will be handled by Parent
*/

interface IUseRetrieve {
  url: string;
  params?: Record<string, any>;
  staleTime?: number;
  enabled?: boolean;
  onSuccess?: Function;
  onError?: (error: AxiosError<any>) => void;
  refetchInterval?: number | false;
}

export default function useRetrieve({
  url,
  params,
  staleTime = 0,
  enabled = true,
  onSuccess,
  onError,
  refetchInterval,
}: IUseRetrieve) {
  return useAPI({
    method: 'get',
    url,
    params,
    staleTime,
    enabled,
    onSuccess,
    onError,
    refetchInterval,
  });
}
