import { ChangeEvent, ReactNode } from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import useCasEnabledDebouncedCallback from 'src/compass/shared/hooks/useCasEnabledDebouncedCallback';

export type ICasTextField = Omit<TextFieldProps, 'onChange'> & {
  onChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, value: any) => void;
  placeholder?: string;
  name: string;
  label?: string;
  startAdornment?: ReactNode;
  className?: string;
  debounce?: boolean;
};

const CasTextField = ({
  label,
  className,
  onChange,
  placeholder,
  name,
  startAdornment,
  debounce = false,
  ...other
}: ICasTextField) => {
  const debouncedOnChange = useCasEnabledDebouncedCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      onChange(event, event.target.value);
    },
    debounce
  );

  return (
    <TextField
      label={label}
      className={className}
      name={name}
      onChange={debouncedOnChange}
      InputProps={{
        startAdornment,
      }}
      {...other}
    />
  );
};
export default CasTextField;
