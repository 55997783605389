import { InputAdornment } from '@mui/material';
import CasRHFTextField, { CasRHFTextFieldProps} from './CasRHFTextField';

export default function CasRHFPercentField(props: CasRHFTextFieldProps) {
  const { label } = props;
  const labelLength = typeof label === 'string' ? label.length : 0;

  return (
    <CasRHFTextField
      {...props}
      type="number"
      fullWidth={false}
      sx={{ maxWidth: Math.max(110, labelLength * 7), pr: 0 }}
      InputProps={{
        endAdornment: <InputAdornment position="end">%</InputAdornment>,
        inputProps: { style: { textAlign: 'right' } },
      }}
    />
  );
}
