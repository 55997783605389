import useUidParam from 'src/compass/shared/hooks/useUidParam';
import PublicLrpQuotesContainer from './_publicLrpQuotes/components/PublicLrpQuotesContainer';
import LrpQuotesPage404 from './_publicLrpQuotes/components/LrpQuotesPage404';

export default function PublicLrpQuotesPage() {
  const uid = useUidParam();
  if (!uid || uid <= 0) return <LrpQuotesPage404 />;

  return <PublicLrpQuotesContainer />;
}
