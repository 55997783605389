import React, { useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { FieldError } from 'react-hook-form';
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { CircularProgress } from '@mui/material';
import { useRetrieveListByGet } from '../../hooks/reactQuery/useRetrieveListByGet';

interface Option {
  id: string;
  name: string;
}

export interface CasAutoCompleteAsyncProps<T = Option>
  extends Omit<
    AutocompleteProps<T, false, false, false>,
    'renderInput' | 'options' | 'value' | 'onInputChange'
  > {
  label?: string;
  placeholder?: string;
  helperText?: React.ReactNode;
  debounceTime?: number;
  onValueChange: (event: React.SyntheticEvent, value: T | null) => void;
  defaultValue?: T | null;
  url: string;
  searchKeyword?: string;
  width?: number | string;
  error?: FieldError;
}

let selectedValueOnFirstTimeChange: any = '';

export default function CasAutoCompleteAsync<T>({
  label,
  placeholder,
  helperText,
  debounceTime = 300,
  onValueChange,
  defaultValue,
  url,
  searchKeyword = 'search',
  width = 250,
  error,
  ...other
}: CasAutoCompleteAsyncProps<T>) {
  const [options, setOptions] = useState<T[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [value, setValue] = useState<T | null>(defaultValue || null);
  const [debouncedValue, setDebouncedValue] = useState('');

  // Debounced input value change
  const debouncedInputChange = useDebouncedCallback((newValue: string) => {
    setDebouncedValue(newValue);
  }, debounceTime);

  // Fetch data using the debounced input value
  const { data, isLoading } = useRetrieveListByGet({
    url: `${url}?${searchKeyword}=${debouncedValue}&pageSize=50&pageNumber=1`,
    staleTime: 0,
    enabled: debouncedValue !== '',
  })

  // Update options when data changes
  useEffect(() => {
    if (data && debouncedValue !== '') {
      setOptions(data || []);
    }
  }, [data, debouncedValue]);

  // Clear options if debouncedValue is empty
  useEffect(() => {
    if (!debouncedValue) {
      setOptions([]);
    }
  }, [debouncedValue]);

  return (
    <Autocomplete
      {...other}
      sx={{ width }}
      options={options}
      loading={isLoading}
      value={value}
      getOptionLabel={(option: any) => {
        if (typeof option === 'string') return option;
        if (option?.name) return option.name;
        return selectedValueOnFirstTimeChange.name;
      }}
      isOptionEqualToValue={(option: any, selectedValue: any) =>
        option?.id === selectedValue?.id
      }
      onChange={(event: React.SyntheticEvent, newValue: any) => {
        selectedValueOnFirstTimeChange = newValue
        setValue(newValue);
        onValueChange(event, newValue?.id || null);
      }}
      inputValue={inputValue}
      onInputChange={(_, newInputValue) => {
        setInputValue(newInputValue);
        debouncedInputChange(newInputValue);
      }}
      noOptionsText={
        debouncedValue === ''
          ? 'Enter a search string'
          : 'No matches'
      }
      loadingText="Loading..."
      renderInput={(params) => (
        <TextField
          {...(params as TextFieldProps)}
          error={!!error}
          helperText={error ? error?.message : helperText}
          label={label}
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading && <CircularProgress size={20} />}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}
