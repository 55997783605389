import { Controller, useFormContext } from 'react-hook-form';
import CasAutoCompleteAsync, { CasAutoCompleteAsyncProps } from './CasAutocompleteAsync';

interface CasRHFAutoCompleteAsyncProps
  extends Omit<CasAutoCompleteAsyncProps, 'onValueChange'> {
  name: string;
  url: string;
}

export default function CasRHFAutoCompleteAsync({
  name,
  defaultValue,
  url,
  ...other
}: CasRHFAutoCompleteAsyncProps) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field, fieldState: { error } }) => (
        <CasAutoCompleteAsync
          {...other}
          url={url}
          defaultValue={field.value}
          error={error}
          onValueChange={(event, value) => {
            field.onChange(value);
          }}
        />
      )}
    />
  );
}
