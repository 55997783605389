import { AxiosError } from 'axios';
import { Typography } from '@mui/material';
import Iconify from 'src/components/iconify';

import CasMessageDialog from './CasMessageDialog';
import { ICasBaseDialog } from './CasBaseDialog';
import { CasStackRow } from '../CasStack/CasStackRow';

type CasErrorDialogProps = ICasBaseDialog & {
  error: AxiosError<any>;
};

export default function CasErrorDialog({ error, ...rest }: CasErrorDialogProps) {
  return (
    <CasMessageDialog {...rest}>
      <CasStackRow alignItems="center" justifyContent="center">
        <Iconify
          width={28}
          height={28}
          color="red"
          icon="material-symbols:warning"
          flexShrink={0}
        />
        <Typography sx={{ wordBreak: 'break-word', whiteSpace: 'pre-line' }}>
          {error.message}
        </Typography>
      </CasStackRow>
    </CasMessageDialog>
  );
}
