import { lazy } from 'react';
import { ICONS } from '../navigationIcons';

const BasisPage = lazy(() => import('src/compass/pages/utilities/BasisPage'));
const DailyPasswordPage = lazy(() => import('src/compass/pages/utilities/DailyPasswordPage'));
const PipPage = lazy(() => import('src/compass/pages/utilities/PipPage'));
const ProprietaryAccountsPage = lazy(
  () => import('src/compass/pages/utilities/ProprietaryAccountsPage')
);
const PoManagementPage = lazy(() => import('src/compass/pages/utilities/PoManagementPage'));
const FeederIndexSetupPage = lazy(() => import('src/compass/pages/utilities/FeederIndexSetupPage'));
const EntityMaintenancePage = lazy(() => import('src/compass/pages/utilities/EntityMaintenancePage'));

export const UtilitiesMenu = [
  {
    menuTitle: 'Utilities',
    path: 'utilities',
    icon: ICONS.user,
    subMenus: [
      {
        title: 'Basis',
        path: 'basis',
        element: <BasisPage />,
        permission: 'UTILITIES_BASIS',
      },
      {
        title: 'Daily Password',
        path: 'dailypassword',
        element: <DailyPasswordPage />,
        permission: 'DAILY_PWD',
      },
      {
        title: 'Entity Maintenance',
        path: 'entities',
        element: <EntityMaintenancePage />,
        permission: 'ENTITIES',
      },
      {
        title: 'Feeder Index Setup',
        path: 'feederindexsetup',
        element: <FeederIndexSetupPage />,
        permission: 'UTILITIES_FDR_IDX_SETUP',
      },
      {
        title: 'PIP',
        path: 'pip',
        element: <PipPage />,
        permission: 'UTILITIES_PIP',
      },
      {
        title: 'PO Management',
        path: 'pomanagement',
        element: <PoManagementPage />,
        permission: 'UTILITIES_PO_MGT',
      },
      {
        title: 'Proprietary Accounts',
        path: 'proprietaryaccounts',
        element: <ProprietaryAccountsPage />,
        permission: 'UTILITIES_PROPRIETARY',
      },
    ],
  },
];
