import { CasRHFSelect, CasSelect, componentProps, CasEntitySelectProps } from 'src/compass/shared/components';
import { Entity } from 'src/compass/site/util';
import useRetrieveEntityListOptions, {
  EntityListOptionsProps,
} from 'src/compass/shared/hooks/reactQuery/useRetrieveEntityListOptions';

function useRiskkWithSalesManagerOptions({
  includeAllOption,
  includeNoneOption,
}: EntityListOptionsProps) {
  return useRetrieveEntityListOptions({
    entity: Entity.RISK_MANAGER_WITH_SALES,
    includeAllOption,
    includeNoneOption,
  });
}

export function CasRiskWithSalesManagerSelect({
  includeAllOption,
  includeNoneOption,
  ...rest
}: componentProps) {
  const { options } = useRiskkWithSalesManagerOptions({ includeAllOption, includeNoneOption });

  return <CasSelect options={options} {...rest} />;
}

export function CasRHFRiskWithSalesManagerSelect({
  includeAllOption,
  includeNoneOption,
  ...rest
}: CasEntitySelectProps) {
  const { options, isLoading } = useRiskkWithSalesManagerOptions({
    includeAllOption,
    includeNoneOption,
  });
  return <CasRHFSelect loading={isLoading} options={options} {...rest} />;
}
