import { lazy } from 'react';
import { ICONS } from '../navigationIcons';

const SettingsPage = lazy(() => import('src/compass/pages/administration/SettingsPage'));
const EHarvestPage = lazy(() => import('src/compass/pages/administration/EHarvestPage'));
const DocusignPage = lazy(() => import('src/compass/pages/administration/DocusignPage'));
const UserOwnerMappingPage = lazy(
  () => import('src/compass/pages/administration/UserOwnerMappingPage')
);
const ReleaseNotesPage = lazy(() => import('src/compass/pages/administration/ReleaseNotesPage'));

export const AdministrationMenu = [
  {
    menuTitle: 'Administration',
    path: 'administration',
    icon: ICONS.user,
    subMenus: [
      {
        title: 'Settings',
        path: 'settings',
        element: <SettingsPage />,
        permission: 'ADMIN',
      },
      {
        title: 'Docusign',
        path: 'docusign',
        element: <DocusignPage />,
        permission: 'ADMIN_DOCUSIGN',
      },
      {
        title: 'eHarvest',
        path: 'eharvest',
        element: <EHarvestPage />,
        permission: 'ADMIN_EHARVEST',
      },
      {
        title: 'User Owner Mapping',
        path: 'userownermapping',
        element: <UserOwnerMappingPage />,
        permission: 'USER_OWNER_MAPPING',
      },
      {
        title: 'Release Notes',
        path: 'releasenotes',
        element: <ReleaseNotesPage />,
        permission: 'RELEASE_NOTES',
      },
    ],
  },
];
