import './_lrpQuotes/styles/lrpQuotes.scss';

import { useEffect, useState } from 'react';

import { CasPage, CasDatePicker } from 'src/compass/shared/components';
import useRetrieve from 'src/compass/shared/hooks/reactQuery/useRetrieve';
import CasTableToolbar from 'src/compass/shared/components/CasTable/CasTableToolbar';
import { DateFormat, formatDate } from 'src/compass/shared/util/casMoment';
import { useExportOption } from 'src/compass/shared/util/useExportOption';
import LrpQuotesContainer from './_lrpQuotes/components/LrpQuotesContainer';

interface Filters {
  selectedQuoteDate: string | null;
}

type LrpQuotesPageProps = { isPublicPage?: boolean }

const defaultFilters = { selectedQuoteDate: null };

const getLRPQuotesTitle = (date: string | null) => {
  if (!date) return 'LRP Quotes';
  const formattedDate = formatDate(date, DateFormat.M_D_YY_ddd);
  return `LRP Quotes: ${formattedDate}`;
};

export default function LrpQuotesPage({ isPublicPage = false } : LrpQuotesPageProps) {
  const [currentFiltersValues, setCurrentFiltersValues] = useState<Filters>({ ...defaultFilters });
  const [displayFiltersValues, setDisplayFiltersValues] = useState<Filters>({ ...defaultFilters });

  const queryResult = useRetrieve({ url: '/LRPQuotes', params: currentFiltersValues });
  const { data } = queryResult;
  const { latestQuoteDate } = data || {};

  useEffect(() => {
    if (displayFiltersValues.selectedQuoteDate === null && latestQuoteDate) {
      setDisplayFiltersValues({ selectedQuoteDate: latestQuoteDate });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [latestQuoteDate]);

  const { exportOption } = useExportOption({
    entity: 'LRPQuotes',
    params: displayFiltersValues,
    includeExport: !!displayFiltersValues.selectedQuoteDate,
  });

  const handleFilters = (_: string, value: string) => {
    setCurrentFiltersValues({ selectedQuoteDate: value });
    setDisplayFiltersValues({ selectedQuoteDate: value });
  };

  return (
    <CasPage
      title={getLRPQuotesTitle(displayFiltersValues.selectedQuoteDate)}
      pageTitle="Endorsements"
      className="lrpQuotesPage"
    >
      {!isPublicPage && (
        <CasTableToolbar
          buttons={[]}
          filters={[
            {
              type: 'component',
              name: 'selectedQuoteDate',
              label: 'Select Date',
              component: CasDatePicker,
            },
          ]}
          currentFilterValues={displayFiltersValues}
          onFilterChange={handleFilters}
          options={exportOption}
        />
      )}

      <LrpQuotesContainer queryResult={queryResult} />
    </CasPage>
  );
}
