import { SetStateAction, Dispatch } from 'react';
import { Mode } from 'react-hook-form';
import useAddMutation from 'src/compass/shared/hooks/reactQuery/useAddMutation';
import useUpdateMutation from 'src/compass/shared/hooks/reactQuery/useUpdateMutation';
import useUploadMutation from 'src/compass/shared/hooks/reactQuery/useUploadMutation';
import CasReactHookForm, { FormMethodType } from './CasReactHookForm';

/*
  TODO to be generic
    - Does it make sense to transform response to match consistent form object structure?
    - e.g.
      - response = {entity, asOfs:[]}
      - form = {entity, asOf:{}}
        + need asOfs list
*/

/*
  onBeforeSave:
    Generally a form should be designed to directly map to the data that will be
    posted to the API.  However, sometimes it may be required to re-shape the submitted
    data for the API.  In this case, onBeforeSave would be specified.
*/
export interface ICasEditFormProps {
  defaultValues: any;
  entity: string;
  schema: any;
  children: any;
  onBeforeSave?: any;
  onSave?: any;
  onSaved?: any;
  fileUpload?: boolean;
  setFormMethods?: Dispatch<SetStateAction<FormMethodType>>;
  mode?: Mode;
}

export default function CasEditForm({
  defaultValues,
  entity,
  schema,
  children,
  onBeforeSave = null,
  onSave = null,
  onSaved = null,
  fileUpload = false,
  setFormMethods,
  mode,
}: ICasEditFormProps) {
  const addMutation = useAddMutation({ url: entity });
  const editMutation = useUpdateMutation({ url: entity });
  const uploadMutation = useUploadMutation({ url: entity });

  const id = defaultValues?.id;
  const editMode = !!id;

  const handleOnSuccess = (payload: any) => {
    if (onSaved) onSaved(payload);
  };

  const onSubmit = (data: any) => {
    const payload = onBeforeSave ? onBeforeSave(data) : data;

    if (onSave) {
      onSave(payload);
    } else if (fileUpload) {
      uploadMutation.mutate(payload, { onSuccess: () => handleOnSuccess(payload) });
    } else if (editMode) {
      editMutation.mutate(payload, { onSuccess: () => handleOnSuccess(payload) });
    } else {
      addMutation.mutate(payload, { onSuccess: () => handleOnSuccess(payload) });
    }
  };

  return (
    <CasReactHookForm
      schema={schema}
      defaultValues={defaultValues}
      onSubmit={onSubmit}
      setFormMethods={setFormMethods}
      mode={mode}
    >
      {children}
    </CasReactHookForm>
  );
}
