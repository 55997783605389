import { SiteLayout, useSiteProperties } from 'src/compass/application';
import { CasLoaderWithBackdrop } from 'src/compass/shared/components';
import LrpQuotesPage from 'src/compass/pages/endorsements/LrpQuotesPage';
import LrpQuotesPage404 from './LrpQuotesPage404';

export default function PublicLrpQuotesContainer() {
  const { isLoading, isError } = useSiteProperties();
  /*
    SiteProperties are retrieved with a loader to force a single BE request to respond
    before sending more.

    Without this workaround, duplicate Web Users could be created because multiple requests (each creating a user)
    would be sent.
    */
  if (isLoading) return <CasLoaderWithBackdrop />;
  if (isError) return <LrpQuotesPage404 />

  return (
    <SiteLayout>
      <LrpQuotesPage isPublicPage />
    </SiteLayout>
  );
}
