import { useEffect, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';

import { CasBaseDialog, CasButton } from 'src/compass/shared/components';
import { CasSecondaryButton } from 'src/compass/shared/components/CasButton/CasSecondaryButton';
import CasReactHookForm, {FormMethodType, ICasReactHookFormProps} from 'src/compass/shared/components/CasForm/CasReactHookForm';

interface CasRHFFormDialogProps extends Omit<ICasReactHookFormProps, 'onSubmit'> {
  open: boolean;
  title: string | React.ReactNode;
  onClose: VoidFunction;
  onSubmit: SubmitHandler<any>;
  //
  submitButtonLabel?: string;
  submitButton?: React.ElementType;
  buttonProps?: Partial<React.ComponentProps<typeof CasButton>>;
}

export default function CasReactHookFormDialog({
  open,
  title,
  schema,
  defaultValues = {},
  children,
  onClose,
  onSubmit,
  // 
  setFormMethods: setFormMethodsByParent,
  // submit button related props
  submitButtonLabel = 'Submit',
  submitButton: SubmitButton = CasButton,
  buttonProps = {},
}: CasRHFFormDialogProps) {
  const [formMethods, setFormMethods] = useState<FormMethodType>(null);

  const handleSubmit = () => formMethods?.handleSubmit(onSubmit)();

  useEffect(() => {
    if (setFormMethodsByParent) setFormMethodsByParent(formMethods);
  }, [formMethods, setFormMethodsByParent]);

  return (
    <CasBaseDialog
      {...{ title, open, onClose }}
      actions={
        <>
          <CasSecondaryButton onClick={onClose}>Cancel</CasSecondaryButton>
          <SubmitButton onClick={handleSubmit} {...buttonProps}>
            {submitButtonLabel}
          </SubmitButton>
        </>
      }
    >
      <CasReactHookForm {...{ schema, defaultValues, setFormMethods }}>
        {children}
      </CasReactHookForm>
    </CasBaseDialog>
  );
}
