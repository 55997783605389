import CasRHFTextField, { CasRHFTextFieldProps } from './CasRHFTextField';

export default function CasRHFNumberField(props: CasRHFTextFieldProps) {
  const { label } = props;
  const labelLength = typeof label === 'string' ? label.length : 0;
  return (
    <CasRHFTextField
      {...props}
      type="number"
      sx={{ maxWidth: Math.max(130, labelLength * 7), pl: 0 }}
    />
  );
}
