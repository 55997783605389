import { AxiosError } from 'axios';
import infoUriFromEntity from 'src/compass/shared/util/infoUriFromEntity';

import { useAPI } from './useAPI';

/*
hook will be used for fetching listing for Select options
Error will be handled by Error Boundary
*/

interface IUseRetrieveEntityList {
  entity: string;
  params?: Record<string, any>;
  staleTime?: number;
  enabled?: boolean;
  onSuccess?: Function;
  onError?: (error: AxiosError) => void;
}

export function useRetrieveEntityList({
  entity,
  params,
  staleTime = 0,
  enabled = true,
  onSuccess,
  onError,
}: IUseRetrieveEntityList) {
  const { data, isLoading, isError, error } = useAPI({
    method: 'get',
    url: infoUriFromEntity(entity),
    params,
    staleTime,
    enabled,
    onSuccess,
    onError,
  });

  if (isError) throw new Error(error.message);

  return { data, isLoading };
}
