import { CasRHFSelect, CasSelect, componentProps, CasEntitySelectProps } from 'src/compass/shared/components';
import { useGetEntityOptions } from 'src/compass/shared/hooks/useGetEntityOptions';
import { Entity } from 'src/compass/site/util';

function useBranchOptions(includeAllOption: boolean= false) {
  return useGetEntityOptions(Entity.BRANCH, includeAllOption);
}

export function RHFBranchSelect(props: CasEntitySelectProps) {
  const { data, isLoading } = useBranchOptions();

  return <CasRHFSelect {...props} loading={isLoading} options={data} />;
}

export function BranchSelect({ includeAllOption, ...rest }: componentProps) {
  const { data } = useBranchOptions(includeAllOption)

  return <CasSelect options={data} {...rest}/>;
}