import { useRef, useEffect } from 'react';

import useCasBoolean, { useCasBooleanProps } from 'src/compass/shared/hooks/useCasBoolean';
import { IGeneralObjectType } from 'src/compass/site/util';
import { useRetrieveDetailsById } from 'src/compass/shared/hooks/reactQuery/useRetrieveDetailsById';
import CasDialog from '../CasDialog/CasDialog';
import CasDeletePopUp from '../CasDeletePopUp/CasDeletePopUp';
import CasErrorDialog from '../CasDialog/CasErrorDialog';
import CasLoaderWithBackdrop from '../CasLoader/CasLoaderWithBackdrop';

interface ICasEditableDialog {
  id?: any;
  entityTitle: string;
  editForm: any;
  entity: string;
  dialog: useCasBooleanProps;
  onChanged: Function;
  canDelete?: boolean;
  deleteLabelSuffix?: string;
  editFormAdditionalData?: IGeneralObjectType;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
}

export default function CasEditableDialog({
  id,
  entityTitle,
  editForm: EditForm,
  entity,
  dialog: editDialog,
  onChanged,
  canDelete = false,
  deleteLabelSuffix = '',
  maxWidth = 'sm',
  editFormAdditionalData,
}: ICasEditableDialog) {
  const editMode = !!id;

  const { data, isFetching, isError, error } = useRetrieveDetailsById({
    entity,
    id,
    enabled: !!id,
  });

  const containerRef = useRef<HTMLSpanElement>(null);

  const deleteDialog = useCasBoolean();
  const errorDialog = useCasBoolean();

  useEffect(() => {
    if (isError) errorDialog.setTrue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  if (!editDialog.value) return null;

  if (isFetching) return <CasLoaderWithBackdrop />;

  return (
    <>
      <CasDialog
        id={id}
        open={editDialog.value && !error}
        saveButtonLabel={editMode ? 'Update' : 'Save'}
        title={(editMode ? 'Edit ' : 'Add ') + entityTitle}
        onSave={() => {
          const node = containerRef.current as any;
          const form = node.querySelector('form');

          form.dispatchEvent(new Event('submit', { bubbles: true, cancelable: true }));
        }}
        onClose={() => editDialog.setFalse()}
        onConfirmDelete={(e: any) => {
          e.preventDefault();
          deleteDialog.setTrue();
        }}
        canDelete={canDelete}
        maxWidth={maxWidth}
        deleteLabelSuffix={deleteLabelSuffix}
      >
        <span ref={containerRef}>
            <EditForm
              row={data}
              onSaved={(savedData: any) => {
                onChanged(savedData);
                editDialog.setFalse();
              }}
              editFormAdditionalData={editFormAdditionalData}
            />
          </span>
      </CasDialog>

      {deleteDialog.value && canDelete && (
        <CasDeletePopUp
          entity={entity}
          entityTitle={entityTitle}
          id={id}
          onDeleted={() => {
            deleteDialog.setFalse();
            onChanged();
          }}
          open={deleteDialog.value}
          onClose={() => {
            deleteDialog.setFalse();
            editDialog.setTrue();
          }}
        />
      )}

      {error && (
        <CasErrorDialog
          open={errorDialog.value}
          onClose={() => {
            errorDialog.setFalse();
            editDialog.setFalse();
          }}
          error={error}
          title={`${entityTitle} Form`}
        />
      )}
    </>
  );
}
