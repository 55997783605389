import CasAlertDialog from './CasAlertDialog';
import CasButton from '../CasButton/CasButton';

export default function CasMessageDialog({ open, title = '', children, onClose }: any) {
  return (
    <CasAlertDialog
      {...{ open, title, onClose }}
      actions={
        <div style={{ textAlign: 'center', flexGrow: 1 }}>
          <CasButton onClick={onClose}>OK</CasButton>
        </div>
      }
    >
      {children}
    </CasAlertDialog>
  );
}
