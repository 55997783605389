/* eslint-disable */
import { useRoutes } from 'react-router-dom';
import { CasAuthenticationHOC } from 'src/compass/site/components';
import Page401 from 'src/compass/pages/error/Page401';
import Page404 from 'src/compass/pages/error/Page404';
import Page500 from 'src/compass/pages/error/Page500';
import Page503 from 'src/compass/pages/error/Page503';
import LogoutPage from 'src/compass/pages/authentication/LogoutPage';
import { useDashboardRoutes, paths  } from 'src/compass/site/routes';
import HomePage from 'src/compass/pages/home/HomePage';
import PublicLrpQuotesPage from 'src/compass/pages/public/PublicLrpQuotesPage';
import { useCasAuthenticated } from 'src/compass/shared/hooks/useUidParam';

const publicRoutes = [
  {
    path: '/lrp-quotes',
    element: <PublicLrpQuotesPage />
  },
];

export default function CasRouter() {
  const protectedDashboardRoutes = useDashboardRoutes();
  const {isAuthenticated, isWebuser} = useCasAuthenticated();

  // not logged  in case
  if (!isAuthenticated
  || isWebuser) {
    return useRoutes([
      ...publicRoutes,
      {
        path: '*',
        element: CasAuthenticationHOC({
          Component: <HomePage />,
        }),
      },
    ]);
  }

  const routing: any = [
    ...publicRoutes,
    ...protectedDashboardRoutes,
    {
      path: paths.logoutPage,
      element: <LogoutPage />
    },
    {
      path: paths.page401,
      element: <Page401 />,
    },
    {
      path: paths.page503,
      element: <Page503 />,
    },
    { path: paths.page500, element: <Page500 /> },
    { path: '*', element: <Page404 /> },
  ];

  return useRoutes(routing);
}
