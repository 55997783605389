import React from 'react';
import { Stack } from '@mui/material';
import CasButton from '../CasButton/CasButton';
import CasErrorDisplay from '../CasError/CasErrorDisplay';

interface ErrorBoundaryState {
  hasError: boolean;
  errorMessage: string | null;
}

interface ErrorBoundaryProps {
  children?: React.ReactNode;
}

class CasErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, errorMessage: null };
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    console.log(error);
    return { hasError: true, errorMessage: error.message };
  }

  resetErrorBoundary = () => {
    this.setState({ hasError: false, errorMessage: null });
  };

  render() {
    const { hasError, errorMessage } = this.state;
    const { children } = this.props;

    if (hasError) {
      return (
        <Stack alignItems="center" marginTop={2} gap={3}>
          <CasErrorDisplay message={errorMessage ?? "Sorry, an error occurred"} />
          <CasButton onClick={this.resetErrorBoundary}>
            Reload
          </CasButton>
        </Stack>
      );
    }

    return children;
  }
}

export default CasErrorBoundary;