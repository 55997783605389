import { UseQueryResult } from '@tanstack/react-query';
import { CasErrorDisplay } from 'src/compass/shared/components';
import CasLoader from 'src/compass/shared/components/CasLoader/CasLoader';
import { DateFormat, formatDate } from 'src/compass/shared/util/casMoment';

import LrpQuotesContent from './LrpQuotesContent';

type QuotesContainerProps = {
  queryResult: UseQueryResult<any, any>;
};

function QuoteMessage({ message }: { message: string }) {
  return <div className="noActiveQuotes-warning">{message}</div>;
}

const getNoQuotesDataMessage = (quoteDate: string, latestQuoteDate: string) => {
  if (quoteDate > latestQuoteDate) {
    return `${formatDate(quoteDate, DateFormat.MDY)} quotes haven't been released`;
  }

  return `${formatDate(quoteDate, DateFormat.MDY)} no quotes available`;
};

// when response data exists
const getInactiveQuotesMessage = (
  quoteDate: string,
  latestQuoteDate: string,
  quotesAreActive: boolean
) => {
  if (!quotesAreActive) {
    if (quoteDate === latestQuoteDate) {
      return `${formatDate(quoteDate, DateFormat.MDY)} quotes are no longer active`;
    }
    return `${formatDate(quoteDate, DateFormat.MDY)} historical quote data`;
  }
  return null;
};

export default function LrpQuotesContainer({queryResult }: QuotesContainerProps) {
  const { isFetching, isError, data } = queryResult;
  const { result, quotesAreActive, latestQuoteDate, quoteDate } = data || {};

  if (isFetching) return <CasLoader />;

  if (isError) return <CasErrorDisplay message="Error retrieving data from the server." />;

  if (result?.length === 0) {
    const noDataMessage = getNoQuotesDataMessage(quoteDate, latestQuoteDate);
    return <QuoteMessage message={noDataMessage} />;
  }

  const inactiveQuoteMessage = getInactiveQuotesMessage( quoteDate, latestQuoteDate, quotesAreActive);

  return (
    <>
      {inactiveQuoteMessage && <QuoteMessage message={inactiveQuoteMessage} />}
      <LrpQuotesContent data={data} />
    </>
  );
}
