import { Stack } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';

import CasSelect, { ICasSelect } from './CasSelect';
import CasIconButton from '../CasButton/CasIconButton';
import { CasStackRow } from '../CasStack/CasStackRow';

export default function CasSelectWithPrevNext(props: ICasSelect) {
  const { value, onChange, options, optionsIdField } = props;

  const currentIndex = options.findIndex(
    (option) => option[optionsIdField || 'id'] === value
  );

  const handleNext = () => {
    const nextValue = options[currentIndex - 1][optionsIdField || 'id'];
    onChange({ target: { value: nextValue } } as SelectChangeEvent<any>, nextValue);
  };

  const handlePrevious = () => {
    const prevValue = options[currentIndex + 1][optionsIdField || 'id'];
    onChange({ target: { value: prevValue } } as SelectChangeEvent<any>, prevValue);
  };

  return (
    <CasStackRow alignItems="center">
      {currentIndex < options.length - 1 && (
        <Stack position="relative" top={-8}>
          <CasIconButton icon="raphael:arrowleft" onClick={handlePrevious} />
        </Stack>
      )}
      <CasSelect {...props} />
      {currentIndex > 0 && (
        <Stack position="relative" top={-8}>
          <CasIconButton icon="raphael:arrowright" onClick={handleNext} />
        </Stack>
      )}
    </CasStackRow>
  );
}
